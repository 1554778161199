<template>

    <AppHead title="Oops! Too Many Requests"/>

    <div class="d-flex flex-column flex-center flex-column-fluid">

        <div class="d-flex flex-column flex-center text-center p-10">

            <div class="card card-flush w-lg-650px py-5">

                <div class="card-body py-15 py-lg-20">

                    <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">Oops! Too Many Requests</h1>

                    <div class="fw-semibold fs-6 text-gray-500 mb-7">Sorry for the hiccup! You've sent us a lot of requests in a short period. Please wait a moment and then try your request again. We appreciate your patience and understanding.</div>

                    <div class="mb-3">
                        <img src="/assets/media/auth/membership.png" class="mw-100 mh-300px theme-light-show"  />
                        <img src="/assets/media/auth/membership-dark.png" class="mw-100 mh-300px theme-dark-show"  />
                    </div>

                    <div class="mb-0">
                        <Link href="/" class="btn btn-sm btn-primary">Go back</Link>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>
