<template>

    <AuthenticationRequiredError v-if="status === 401" />

    <AccessDeniedError v-if="status === 403" />

    <PageNotFoundError v-if="status === 404" />

    <SessionExpiredError v-if="status === 419" />

    <TooManyRequestsError v-if="status === 429" />

    <SomethingWentWrongError v-if="status === 500" />

    <MaintenanceError v-if="status === 503" />

</template>
<script setup>
import { usePage } from '@inertiajs/vue3'
import AuthenticationRequiredError from '@/Shares/Errors/401.vue'
import AccessDeniedError from '@/Shares/Errors/403.vue'
import PageNotFoundError from '@/Shares/Errors/404.vue'
import SessionExpiredError from '@/Shares/Errors/419.vue'
import TooManyRequestsError from '@/Shares/Errors/429.vue'
import SomethingWentWrongError from '@/Shares/Errors/500.vue'
import MaintenanceError from '@/Shares/Errors/503.vue'


// Define variables
const page = usePage();
const status = page.props.status;

</script>
